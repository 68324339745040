import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { queryGroups } from '@wix/ambassador-social-groups-v2-group/http';
import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';

import {
  initializeCommentsController,
  PaginationState,
} from '@wix/comments-ooi-client/controller';

import { COMPONENT_ID } from 'common/utils/utils';

import * as group from 'store/group';
import * as feed from 'store/feed';
import * as application from 'store/application';

import settingsParams from './settingsParams';
import { createStore } from './store';
import { groupSelector } from './Widget/selectors';
import { FEED_WIDGET_WARMUP_DATA_KEY } from '../../config/constants';

const createController: CreateControllerFn = async (controllerContext) => {
  const { flowAPI } = controllerContext;
  const { wixCodeApi, setProps, appParams } = flowAPI.controllerConfig;

  const store = createStore(controllerContext);

  const postsCount = flowAPI.settings.get(settingsParams.postsCount);
  let groupId = flowAPI.settings.get(settingsParams.groupId);

  const { isSSR } = flowAPI.environment;
  const { warmupData } = wixCodeApi.window;

  return {
    async pageReady() {
      await store.dispatch(
        application.thunks.login(wixCodeApi.user.currentUser),
      );
      // if in SSR or in local env (SSR is disabled)
      if (isSSR || !warmupData.get(FEED_WIDGET_WARMUP_DATA_KEY)) {
        if (!groupId) {
          groupId = await determineGroup();
        }

        if (groupId) {
          await Promise.all([fetchGroup(groupId), fetch(groupId, postsCount)]);
        }
      }

      const state = store.getState();

      if (isSSR) {
        warmupData.set(FEED_WIDGET_WARMUP_DATA_KEY, state);
      }

      setProps({
        store: state,
        group: {
          fetchGroup,
          redirectToGroup,
        },
        feed: {
          pin,
          unpin,
          fetch,
          react,
          unreact,
          subscribe,
          unsubscribe,
          fetchMore,
        },
      });

      store.subscribe(() => {
        setProps({
          store: store.getState(),
        });
      });

      wixCodeApi.user.onLogin(handleUserLogin);

      const commentsApi = await initializeCommentsController(
        flowAPI.controllerConfig,
        {
          appDefinitionId: appParams.appDefinitionId,
          httpClient: flowAPI.httpClient,
        },
      );

      commentsApi.watch.pagination.onChange(handleCommentsChange);
    },
  };

  async function redirectToGroup(searchParams?: string[][]) {
    const group = groupSelector(store.getState());
    const params = new URLSearchParams(searchParams);
    const { url } = await wixCodeApi.site.getSectionUrl({
      appDefinitionId: appParams.appDefinitionId,
      sectionId: COMPONENT_ID.GROUP,
    });

    wixCodeApi.location.to?.(
      `${url}/${group.slug || group.id}?${params.toString()}`,
    );
  }

  function fetchGroup(id: string) {
    return store.dispatch(group.thunks.fetchGroup(id));
  }

  async function determineGroup() {
    const response = await flowAPI.httpClient.request(
      queryGroups({
        query: {
          filter: {
            privacyLevel: PrivacyStatus.PUBLIC,
          },
        },
      }),
    );

    const group = response.data.groups?.[0];

    return group?.id || '';
  }

  async function handleUserLogin() {
    await store.dispatch(application.thunks.login(wixCodeApi.user.currentUser));
    fetchGroup(groupId);
    fetch(groupId);
  }

  function subscribe(feedItemId: string) {
    return store.dispatch(
      feed.thunks.subscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  function unsubscribe(feedItemId: string) {
    return store.dispatch(
      feed.thunks.unsubscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  async function pin(feedItemId: string) {
    const state = store.getState();

    const pinned = feed.selectors
      .selectAll(state.feed)
      .find((item) => !!item.pin);

    if (pinned) {
      await unpin(pinned.feedItemId!);
    }

    return store.dispatch(
      feed.thunks.pin({
        feedItemId,
        groupId,
      }),
    );
  }

  function unpin(feedItemId: string) {
    return store.dispatch(
      feed.thunks.unpin({
        feedItemId,
        groupId,
      }),
    );
  }

  function react(feedItemId: string, code: string) {
    return store.dispatch(
      feed.thunks.react({
        code,
        groupId,
        feedItemId,
      }),
    );
  }

  function unreact(feedItemId: string, code: string) {
    return store.dispatch(
      feed.thunks.unreact({
        code,
        groupId,
        feedItemId,
      }),
    );
  }

  function fetch(groupId: string, limit: number = 10) {
    return store.dispatch(
      feed.thunks.fetch({
        groupId,
        limit,
      }),
    );
  }

  function fetchMore(limit: number = 10) {
    const state = store.getState();
    return store.dispatch(
      feed.thunks.fetchNext({
        groupId,
        limit,
        cursor: state.feed.nextCursor,
      }),
    );
  }

  function handleCommentsChange(paginationState: PaginationState) {
    return store.dispatch(
      feed.actions.updateTotalComments(
        Object.fromEntries(
          Object.entries(paginationState).filter((data) => {
            const [, state] = data;

            return state.type === 'READY';
          }),
        ),
      ),
    );
  }
};

export default createController;
